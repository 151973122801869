<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="fl department_div">
        <el-tree
          class="deptTree"
          default-expand-all
          node-key="id"
          :data="deptList"
          :props="defaultProps"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          :render-content="renderContent"
          :highlight-current="true"
        >
        </el-tree>
      </div>
      <div class="table_right">
        <div class="mainList_operation clearfloat">
          <div class="mainList_operation_fr">
            <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
          </div>
        </div>
        <div class="mainList_content">
          <div class="mainList_content_sub">
            <el-table
              ref="multipleTable"
              row-key="id"
              border
              :data="tableData"
              :default-expand-all="true"
              tooltip-effect="dark"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              height="string"
              v-loading="loading"
            >
              <el-table-column align="center" type="index" label="序号"></el-table-column>
              <el-table-column prop="postCode" align="center" label="岗位编码"></el-table-column>
              <el-table-column align="center" prop="postName" label="岗位名称"></el-table-column>
              <el-table-column prop="weight" align="center" sortable label="权重"></el-table-column>
              <el-table-column label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="text_Edit_Bgc"
                    @click.stop="edit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button type="text" size="small" class="text_Remove_Bgc" @click="del(scope)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <EditDialog
      width="600px"
      :isShow.sync="showEditDialog"
      :loading.sync="editDialogLoading"
      @submit="submit"
    >
      <div slot="title" class="title">{{ form.id ? '编辑' : '新增' }}岗位</div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="form" ref="form">
          <!-- <el-form-item label="部门" prop="deptId" ref="deptId">
                        <el-input v-model="form.postCode" placeholder="请选择部门" @keyup.enter.native="submit"></el-input>
                    </el-form-item> -->
          <el-form-item label="部门" prop="deptId" ref="deptId">
            <Treeselect
              :normalizer="normalizer"
              @input="changeParentId"
              placeholder="请选择部门"
              v-model="form.deptId"
              :options="treeselectOptions"
            >
            </Treeselect>
          </el-form-item>

          <el-form-item label="岗位编码" prop="deptId" ref="deptId">
            <el-input
              v-model="form.postCode"
              placeholder="请输入岗位编码"
              @keyup.enter.native="submit"
            ></el-input>
          </el-form-item>

          <el-form-item label="岗位名称" prop="postName" ref="postName">
            <el-input
              v-model="form.postName"
              placeholder="请输入岗位名称"
              @keyup.enter.native="submit"
            ></el-input>
          </el-form-item>

          <el-form-item label="岗位薪资" prop="averageSalary" ref="averageSalary">
            <el-input
              v-model="form.averageSalary"
              placeholder="请输入岗位薪资"
              @keyup.enter.native="submit"
            ></el-input>
          </el-form-item>

          <el-form-item label="岗位权重" prop="weight" ref="weight">
            <el-input
              v-model="form.weight"
              placeholder="请输入岗位权重"
              @keyup.enter.native="submit"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { initTreeData } from '@/util/common'

export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Treeselect: () => import('@riophae/vue-treeselect'),
  },
  data() {
    return {
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      editDialogLoading: false,
      parameter: {
        deptId: '',
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },

      rules: {
        deptName: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: ['blur', 'change'],
          },
        ],
        deptShortname: [
          {
            required: true,
            message: '请输入岗位简称',
            trigger: ['blur', 'change'],
          },
        ],
        deptCode: [
          {
            required: true,
            message: '请输入岗位编码',
            trigger: ['blur', 'change'],
          },
        ],
      },
      treeselectOptions: [],
      deptList: [], // 部门 树形列表
      // 部门 树形对象 结构
      defaultProps: {
        children: 'children',
        label: 'deptName',
      },
      renderContent: function (h, { node, data, store }) {
        let addElement = arguments[0]
        if (data.level == 1) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcompany iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 2) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcangku iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 3) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconconnections iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 4) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconCustomermanagement iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        }
      },
    }
  },
  created() {
    this.getDeptList()
    this.getSept()
    this.getData()
  },
  methods: {
    add() {
      this.form = {}
      this.showEditDialog = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    edit(row) {
      this.form = Object.assign({}, row)
      this.showEditDialog = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.deptName,
        children: node.children,
      }
    },
    changeParentId() {
      this.$refs.form.validateField('parentId')
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.editDialogLoading = true

          if (this.form.id) {
            this.$api.sysConfig
              .postEdit(Object.assign(this.form))
              .then(res => {
                this.editDialogLoading = false
                this.showEditDialog = false
                this.$message.success('操作成功！')
                this.getData()
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          } else {
            this.$api.sysConfig
              .postAdd(Object.assign(this.form))
              .then(res => {
                this.editDialogLoading = false
                this.showEditDialog = false
                this.$message.success('操作成功！')
                this.getData()
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          }
        } else {
          this.scrollView(obj)
        }
      })
    },
    getData() {
      this.loading = true
      this.$api.sysConfig
        .listPost(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.sysConfig
            .postDel(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')
              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNow >= this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNow > 1) {
                this.parameter.pageNow--
              }
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    getSept() {
      this.loading = true
      this.$api.sysDept
        .listDept()
        .then(res => {
          this.loading = false
          this.SeptData = initTreeData(res.data)
          this.treeselectOptions = [
            {
              id: 0,
              deptName: '顶级部门',
              children: this.SeptData,
            },
          ]
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**
     * 点击 "部门" 树形列表
     * @param data      点击获取数据
     */
    handleNodeClick(data) {
      console.log(data)
      this.parameter.pageNow = 1
      this.parameter.deptId = data.id

      this.getData()
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        // .listDept()
        .getTreeDept()
        .then(res => {
          // let _arry = []
          // for (let i = 0; i < res.data.length; i++) {
          //   _arry.push({
          //     id: res.data[i].id,
          //     parentId: res.data[i].parentId,
          //     label: res.data[i].deptName,
          //   })
          // }
          // this.deptList = initTreeData(_arry)
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList {
  display: block;
}
.department_div {
  width: 246px;
  height: 100%;
  background-color: #ffffff;
  margin-right: 20px;
  padding: 10px 10px;
}

/deep/.el-tree {
  overflow: hidden;
}

.table_right {
  float: right;
  width: calc(100% - 266px);
  height: calc(100% - 90px);
}
/deep/.iconDept {
  color: #399ffb;
}
/deep/.deptName {
  font-size: 14px;
}
</style>
